import { Tab } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { Entry } from "contentful";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import EscapeNewLine from "~/components/common/escape-new-line";
import HighlightedText from "~/components/common/highlighted-text";
import { useBreakpoint } from "~/contexts/breakpoint";
import { useConfiguration } from "~/contexts/configuration";
import Editorials from "~/types/editorials";
import { basicAnimationStates } from "~/utils/animation-utils";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import evaUtils from "~/utils/eva-utils";
import productUtils from "~/utils/product-utils";

import { CarouselWrapper } from "../carousel-utils/carousel-utils";
import EditorialLink from "../editorial-link/editorial-link";
import ProductCarouselSwiper from "../product-carousel/product-carousel-swiper";
import styles from "./tabbed-product-carousel.module.scss";
import TabbedProductCarouselBackground from "./tabbed-product-carousel-background";

type Props = {
  entry: Entry<unknown>;
};

export default function TabbedProductCarousel(props: Props) {
  assertEditorialType<Editorials.TabbedProductCarousel>(props.entry, "tabbedProductCarousel");

  const { eva } = useConfiguration();
  const { introText, tabs, backgroundColor } = props.entry.fields;
  const breakpoint = useBreakpoint();

  const [tabIsClicked, setTabIsClicked] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const tabsWithProducts = tabs.filter(
    (tab) => isActiveEntry(tab) && !!tab.fields.products && isActiveEntry(tab.fields.products)
  );

  const { data } = useQuery({
    queryKey: ["productCarousel", tabsWithProducts.map((tab) => tab.sys.id)],
    staleTime: Infinity,
    queryFn: async () => {
      assertEditorialType<Editorials.TabbedProductCarousel>(props.entry, "tabbedProductCarousel");
      return await Promise.all(
        tabsWithProducts.map(async (tab) => {
          const productReferenceFields = tab.fields.products.fields;
          const sortBy = productReferenceFields.sortBy;
          const sort = productUtils.getSortFieldDescriptorByContentful(sortBy);

          if ("productIds" in productReferenceFields) {
            const response = await evaUtils.getProductsByIdsWithChildren(eva, productReferenceFields.productIds, sort);
            return response.Products?.filter((p) => productUtils.hasValidDisplayPrice(p)) ?? [];
          } else {
            const response = await evaUtils.getProductsBySetWithChildren(
              eva,
              productReferenceFields.productSetId,
              sort
            );
            return response.Products?.filter((p) => productUtils.hasValidDisplayPrice(p)) ?? [];
          }
        })
      );
    },
  });

  const tabsFiltered = tabsWithProducts.filter((_, index) => data && data[index].length > 0);
  const { title, description, link, backgroundColors } = tabsFiltered[activeIndex]?.fields || {};

  const refTabs = useRef<HTMLElement[] | null[] | undefined[]>([]);
  const previousIndexRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout;
    function translateAfterSelect() {
      const parentPos = refTabs.current[activeIndex]?.parentElement?.getBoundingClientRect();
      const childPos = refTabs.current[activeIndex]?.getBoundingClientRect();
      if (parentPos && childPos) {
        const relativeOffset = childPos.left - parentPos.left;
        refTabs.current[activeIndex]?.parentElement?.scrollTo({ left: relativeOffset + 24, behavior: "smooth" });
      }
    }
    if ((previousIndexRef.current == undefined && activeIndex != 0) || previousIndexRef.current != undefined) {
      scrollTimeout = setTimeout(() => {
        translateAfterSelect();
      }, 500);
      previousIndexRef.current = activeIndex;
    }
    return () => clearTimeout(scrollTimeout);
  }, [activeIndex]);

  const dataFiltered = data?.filter((_, index) => data && data[index].length > 0);
  if (tabsFiltered.length === 0) return null;

  const inspectorMode = contentfulUtils.useInspectorMode(props.entry);
  const inspectorModeTab = contentfulUtils.useInspectorMode(tabsFiltered[activeIndex]);

  return (
    <AnimatePresence>
      <div className={classNames(styles.container, backgroundColor ? styles[backgroundColor] : undefined)}>
        <TabbedProductCarouselBackground
          color1={backgroundColors[0]}
          color2={backgroundColors[1] ? backgroundColors[1] : backgroundColors[0]}
        />
        <div className={styles.introContainer}>
          {introText ? (
            <div {...inspectorMode?.getProps("introText")} className={styles.introText}>
              <EscapeNewLine text={introText} />
            </div>
          ) : null}
        </div>
        <div className={styles.tabWrapper}>
          <motion.div
            key={`${tabsFiltered[activeIndex].sys.id}-text`}
            className={styles.textWrapper}
            initial={basicAnimationStates.off}
            animate={basicAnimationStates.on}
            exit={basicAnimationStates.off}
            transition={{ duration: 0.3 }}
          >
            {title ? (
              <h2
                {...inspectorModeTab?.getProps("title")}
                className={contentfulUtils.isHighlightText(title) ? styles.titleHighlighted : styles.title}
              >
                <HighlightedText text={title} />
              </h2>
            ) : null}
            {description ? (
              <div {...inspectorModeTab?.getProps("description")} className={styles.description}>
                <EscapeNewLine text={description} />
              </div>
            ) : null}
            {link && breakpoint === "mobile" ? (
              <EditorialLink className={styles.link} entry={link} lookAsButton="secondary" />
            ) : null}
          </motion.div>
          <Tab.Group selectedIndex={activeIndex} onChange={setActiveIndex}>
            <Tab.List className={styles.tabListContainer} aria-roledescription="tablist">
              {tabsFiltered.map((tab, index) => {
                const inspectorModeTabButton = contentfulUtils.useInspectorMode(tab);

                return (
                  <Tab
                    onClick={() => setTabIsClicked(true)}
                    onKeyUp={(e) => (e.key === "Tab" ? setTabIsClicked(false) : undefined)}
                    key={`${tab.sys.id}-tab-${index}`}
                    className={classNames(styles.tab, tabIsClicked ? styles.clickedTab : undefined)}
                    aria-roledescription="tab"
                    aria-controls={`${tab.sys.id}-panel-${index}`}
                    ref={(ref) => {
                      if (ref) refTabs.current[index] = ref;
                    }}
                    {...inspectorModeTabButton?.getProps("tabTitle")}
                  >
                    {({ selected }) => (
                      <>
                        {selected && (
                          <span className={styles.tabTitleSelected}>
                            <EscapeNewLine text={tab.fields.tabTitle} />
                          </span>
                        )}
                        {!selected && (
                          <span className={styles.tabTitle}>
                            <EscapeNewLine text={tab.fields.tabTitle} />
                          </span>
                        )}
                        {selected && (
                          <motion.div
                            className={styles.selectedTab}
                            style={{
                              background:
                                tab.fields.tabColors.length > 1
                                  ? ` linear-gradient(90deg, ${tab.fields.tabColors[0]} 0%, ${tab.fields.tabColors[1]} 100%`
                                  : `${tab.fields.tabColors[0]}`,
                            }}
                            layoutId={`selected-TabbedProductCarousel-${props.entry.sys.id}`}
                          />
                        )}
                      </>
                    )}
                  </Tab>
                );
              })}
            </Tab.List>
            <Tab.Panels className={styles.panels}>
              {dataFiltered && dataFiltered.length > 0
                ? tabsFiltered.map((tab, index) => {
                    return (
                      <Tab.Panel
                        as={motion.div}
                        key={`${tab.sys.id}-panel-${index}`}
                        aria-labelledby={`${tab.sys.id}-tab-${index}`}
                        aria-roledescription="tabpanel"
                        initial={basicAnimationStates.off}
                        animate={basicAnimationStates.on}
                        exit={basicAnimationStates.off}
                        transition={{ duration: 0.6 }}
                        className={styles.panel}
                      >
                        <CarouselWrapper>
                          <ProductCarouselSwiper
                            products={dataFiltered[index]}
                            listScope={props.entry.sys.id}
                            enableLoop={tab.fields.enableLoop}
                          />
                        </CarouselWrapper>
                      </Tab.Panel>
                    );
                  })
                : null}
            </Tab.Panels>
          </Tab.Group>
          {link && breakpoint === "desktop" ? (
            <EditorialLink className={styles.link} entry={link} lookAsButton="secondary" />
          ) : null}
        </div>
      </div>
    </AnimatePresence>
  );
}
